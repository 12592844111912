// See https://auth0.com/blog/securing-gatsby-with-auth0/
import auth0 from 'auth0-js'
import { navigate } from 'gatsby'
import { generateRandomString } from './string'

const isBrowser = typeof window !== 'undefined'
const auth = isBrowser
  ? new auth0.WebAuth({
      domain: process.env.GATSBY_AUTH0_ISSUER_BASE_URL,
      clientID: process.env.GATSBY_AUTH0_CLIENT_ID,
      redirectUri: process.env.GATSBY_AUTH0_CALLBACK,
      responseType: 'token id_token',
      scope: 'openid profile email'
    })
  : {}

let user = {}
let isCheckingSession = false
let tabcatUserAuthToken

export const isAuthenticated = () => {
  if (!isBrowser) {
    return
  }

  const isUserLoggedIn = localStorage.getItem('tabcat-isUserLoggedIn')
  const expiresAt = localStorage.getItem('tabcat-userAuthToken-expiresAt')

  if (isUserLoggedIn && expiresAt > new Date().getTime()) {
    return true
  }

  return
}

export const login = () => {
  if (!isBrowser) {
    return
  }

  const state = generateRandomString()
  localStorage.setItem('state', state)
  auth.authorize({ state })
}

export const logout = () => {
  if (!isBrowser) {
    return
  }

  localStorage.removeItem('tabcat-isUserLoggedIn')
  tabcatUserAuthToken = null
  localStorage.removeItem('tabcat-userAuthToken-expiresAt')

  auth.logout({
    returnTo: process.env.GATSBY_AUTH0_CALLBACK,
    clientID: process.env.GATSBY_AUTH0_CLIENT_ID
  })
}

const setSession = (cb = () => {}) => (err, authResult) => {
  if (err) {
    console.error('SetSession error: ', err)
    if (err.code === 'login_required') {
      login()
    }

    cb()
    return err
  }

  if (authResult && authResult.accessToken && authResult.idToken) {
    user = authResult.idTokenPayload

    // This is global session, which still runs if you close the window and come back later (but don't logout),
    // that's why it is stored in local storage. authResult.expiresIn = 7200. That * 1000 = 120 minutes in total
    // The local session runs in the IddleWrapper component
    let expiresAt = authResult.expiresIn * 1000 + new Date().getTime()
    localStorage.setItem('tabcat-isUserLoggedIn', true)
    tabcatUserAuthToken = authResult.idToken
    localStorage.setItem('tabcat-userAuthToken-expiresAt', expiresAt)

    if (isCheckingSession) {
      isCheckingSession = false
    } else {
      // logged in for the first time, direct to studies page
      navigate('/studies')
    }

    cb()
  }
}

export const handleAuthentication = async () => {
  let authResult
  if (!isBrowser) {
    return
  }

  const state = localStorage.getItem('state')

  authResult = await new Promise((resolve, reject) => {
    auth.parseHash({ state }, (err, authResult) => {
      if (err) reject(err)
      resolve(authResult)
    })
  }).catch(err => {
    throw setSession()(err, null)
  })
  if (authResult) {
    setSession()(null, authResult)
  }
  return authResult
}

export const getProfile = () => {
  return user
}

export const silentAuth = async callback => {
  let authResult
  isCheckingSession = true
  /** browser must have third-party cookies enabled. TODO (david): cookie notification */

  authResult = await new Promise((resolve, reject) => {
    auth.checkSession({}, (err, authResult) => {
      if (err) reject(err)
      resolve(authResult)
    })
  }).catch(err => {
    throw setSession()(err, null)
  })
  if (authResult) {
    setSession(callback)(null, authResult)
  }

  return authResult
}

export const changePassword = (email, callback) => {
  auth.changePassword(
    {
      connection: 'Username-Password-Authentication',
      email
    },
    callback
  )
}

export const getToken = () => {
  if (tabcatUserAuthToken) return tabcatUserAuthToken
}

export const getUserRole = () => {
  const user = getProfile()
  return user['https://hasura.io/jwt/claims'][
    'x-hasura-allowed-roles'
  ].includes('System Admin')
    ? 'System Admin'
    : user['https://hasura.io/jwt/claims']['x-hasura-allowed-roles'].includes(
        'Realm Admin'
      )
    ? 'Realm Admin'
    : user['https://hasura.io/jwt/claims']['x-hasura-allowed-roles'].includes(
        'Data Admin'
      )
    ? 'Data Admin'
    : user['https://hasura.io/jwt/claims']['x-hasura-allowed-roles'].includes(
        'Rater'
      )
    ? 'Rater'
    : 'user'
}
